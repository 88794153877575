import { ImagePreview } from "./image-preview";
import { DocumentPreview } from "./document-previewer";
import React from "react";

const isImage = (file: any) => {
  const ext = file?.key.split(".").pop() || "";
  return ["png", "jpg", "jpeg", "gif"].indexOf(ext) !== -1;
};

export const FilePreview = ({
  file,
  onDelete,
}: {
  file: string;
  onDelete: (file: string) => void;
}) => {
  return isImage(file) ? (
    <ImagePreview file={file} onDelete={onDelete} />
  ) : (
    <DocumentPreview file={file} onDelete={onDelete} />
  );
};

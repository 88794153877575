// import React from "react";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import ComponentWrapper from "../../components/ComponentWrapper";
import { currentUserDetails } from "src/config/api/auth";

const PrivateRoute = ({ children }: any) => {
  const { userDetails, setUserDetails } = useContext(UserContext);

  const getUserDetails = async () => {
    const { data: userData }: any = await currentUserDetails();
    setUserDetails(userData);
  };

  if (Object.keys(userDetails).length === 0) {
    if (window.localStorage.getItem("token")) {
      getUserDetails();
    } else {
      return <Navigate to="/login" replace />;
    }
  }

  if (!window.localStorage.getItem("token")) {
    return <Navigate to="/login" replace />;
  }

  return <ComponentWrapper>{children}</ComponentWrapper>;
};

export default PrivateRoute;

import React, { useContext, useState } from "react";
import * as yup from "yup";
import { Form, Input } from "@lipihipi/form";
import { adminLogin, currentUserDetails } from "src/config/api/auth";
import { UserContext } from "src/context/user-context";
import { Navigate, useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import Logo from "../../img/logo.png";

const validationSchema = {
  login: yup.object().shape({
    email: yup.string().email().required("Email is required"),

    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum."),
  }),
};

const Login = () => {
  const navigate = useNavigate();
  const { setUserDetails } = useContext(UserContext);
  const [error, setError] = useState<any>();

  if (window.localStorage.getItem("token")) {
    return <Navigate to="/listing" replace />;
  }

  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      const { data: loginResponse }: any = await adminLogin(values);
      window.localStorage.setItem("token", loginResponse.token);
      await getUserDetails();
      await navigate("/listing");
    } catch (error) {
      setError("Invalid email or password");
    }
  };

  const getUserDetails = async () => {
    try {
      const { data: userData }: any = await currentUserDetails();
      setUserDetails(userData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <main className="user-structure">
      <div className="user-structure--info">
        <div className="info-header">
          <img src={Logo} alt="Logo" width="150px" />
        </div>
        <div className="info-body text-center">
          <img src={Logo} alt="logo" />
        </div>
        {/* <div className="info-footer">
          <ul>
            <li>
              <a
                className="fb"
                href="https://www.facebook.com/TheEduCrackApp"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                className="lk"
                href="https://www.linkedin.com/company/educrack/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                className="ins"
                href="https://www.instagram.com/theeducrackapp/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      <div className="user-structure--form">
        <div className="wrap">
          <h2>Login to Lexicon Eduversity</h2>
          <Form
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema.login}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <div className="form-group">
              <div>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  label="Email Address"
                  required={true}
                />
              </div>
            </div>
            <div className="form-group">
              <Input
                id="password"
                name="password"
                type="password"
                label="Password"
                required
              />
            </div>
            {/* <div className="d-flex align-items-center">
              <a href="/forgot-password">Forgot Password?</a>
            </div> */}
            {error && (
              <div className="text-danger">Invalid email or password</div>
            )}
            <div className="button-group">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: "100%" }}
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </main>
  );
};

export default Login;

import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "src/context/user-context";

const Logout = () => {
  const { setUserDetails } = useContext(UserContext);

  useEffect(() => {
    localStorage.removeItem("token");
    setUserDetails({});
  }, []);

  return <Navigate to="/login" />;
};

export default Logout;

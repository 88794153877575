export enum GrantResources {
  WALL = "WALL",
  DOUBTS = "DOUBTS",
  CMS = "CMS",
  COURSE = "COURSE",
  MANAGE_BATCH = "MANAGE_BATCH",
  MANAGE_EDUCATORS = "MANAGE_EDUCATORS",
  QUESTIONS = "QUESTIONS",
  TESTS = "TESTS",
  TEST_BUNDLES = "TEST_BUNDLES",
  LIBRARY = "LIBRARY",
  SUPPORT = "SUPPORT",
  USERS = "USERS",
  STUDENT_PROFILE = "STUDENT_PROFILE",
  INTERVIEW = "INTERVIEW",
  REPORT = "REPORT",
  LIVE_SESSIONS = "LIVE_SESSIONS",
  MANAGE_ASSIGNMENTS = "MANAGE_ASSIGNMENTS",
  COUPON = "COUPON",
  RESUME = "RESUME",
  MANAGE_MARKETING = "MANAGE_MARKETING",
  "MANAGE_CENTERS" = "MANAGE_CENTERS",
  MANAGE_FREE_RESOURCE = "MANAGE_FREE_RESOURCE",
  PSYCHOMETRIC_TEST = "PSYCHOMETRIC_TEST",
}

import React from "react";
import { ImageContainer, Image, IconButton } from "./file-upload.styled";
import { AiFillCloseCircle } from "react-icons/ai";

export const ImagePreview = ({ file, onDelete }: any) => {
  return (
    <ImageContainer className="d-flex justify-content-center align-items-center">
      <IconButton
        onClick={() => onDelete(file?.key)}
        className="d-flex justify-content-center align-items-center"
      >
        <AiFillCloseCircle />
      </IconButton>
      <Image src={file.url} alt="" />
    </ImageContainer>
  );
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Button } from "reactstrap";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="sidebar">
      <ul>
        <li>
          <Link to="/listing">Dashboard</Link>
        </li>
        <li>
          <Button className={isOpen ? "active" : ""} onClick={toggle}>
            Business & Permissions
            <svg
              className={isOpen ? "active" : ""}
              stroke="currentColor"
              fill="none"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke-linecap="round"
              stroke-linejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </Button>
          <Collapse isOpen={isOpen}>
            <ul>
              <li>
                <Link to="/listing">Business Partners</Link>
              </li>
            </ul>
          </Collapse>
        </li>
        <li>
          <Link to="/logout">Logout</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;

import Listing from "./pages/Listing";
import Detail from "./pages/add-edit-institution";
import Dashboard from "./pages/dashboard";
import Logout from "./pages/Auth/logout";

export const routes = [
  {
    component: Dashboard,
    route: "/",
    routeProps: { exact: true },
  },
  {
    component: Listing,
    route: "/listing",
    routeProps: { exact: true },
  },

  {
    component: Detail,
    route: "/add-institution",
    routeProps: { exact: true },
  },
  {
    component: Detail,
    route: "/edit-institution/:institutionId",
    routeProps: { exact: true },
  },
  {
    component: Logout,
    route: "/logout",
    routeProps: { exact: true },
  },
];

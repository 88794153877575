import * as Yup from "yup";

const MOBILE_REGEX = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const FormSchema = Yup.object().shape({
  name: Yup.string().required("Institution name is required"),
  centerCode: Yup.string().required("Center Code is required"),
  gstName: Yup.string().required("GST Name is required"),
  gstNumber: Yup.string().required("GST Number is required"),
  admin: Yup.string(),
  student: Yup.string(),
  email: Yup.string()
    .matches(EMAIL_REGEX, "Invalid Email")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(MOBILE_REGEX, "Invalid Mobile No.")
    .required("Mobile Number is required"),
  proctored: Yup.string(),
});

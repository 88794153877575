import React, { createContext, useState } from "react";

export interface ContextProps {
  userDetails: any;
  setUserDetails: (data: any) => any;
}

export const UserContext = createContext<ContextProps>({
  userDetails: {},
  setUserDetails: () => {},
});

const UserContextProvider = ({ children }: any) => {
  const [userDetails, setUserDetails] = useState({});

  return (
    <UserContext.Provider value={{ userDetails, setUserDetails }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import ICON from "../../img/icon.jpg";
import DOTS from "../../img/dots.svg";
import { getTenantList } from "src/config/api/tenant";
import { Loader } from "@lipihipi/ec-ui";
import moment from "moment";

const Listing = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [tenantList, setTenantList] = useState<any>({
    list: [],
  });

  useLayoutEffect(() => {
    const getAllTenants = async () => {
      try {
        const { data: tenantListData }: any = await getTenantList();
        setTenantList(tenantListData);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    getAllTenants();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="page-header">
            <div className="wrap">
              <h1>Business Partners</h1>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">Business & Permissions</Link>
                </li>
                <li>
                  <Link to="/">Business Partners</Link>
                </li>
              </ul>
            </div>

            <button onClick={() => navigate("/add-institution")}>
              Add an Institution
            </button>
          </div>

          <Table>
            <colgroup>
              <col width={300} />
              <col width={300} />
              <col width={150} />
              <col width={`calc(100%  - (250px - 500px))`} />
            </colgroup>
            <thead>
              <tr>
                <th>Name of Institutions</th>
                <th>URL</th>
                <th>Institution Type</th>
                <th className="text-end">Status</th>
              </tr>
            </thead>
            <tbody>
              {tenantList?.list.length > 0 &&
                tenantList?.list?.map((tenant: any) => (
                  <tr>
                    <td>
                      <div className="primary-info">
                        <img src={ICON} alt="" />
                        <p>
                          {tenant.name}
                          <span>{tenant.email}</span>
                        </p>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <>
                          {tenant?.eduDomains?.admin && (
                            <a
                              href={`http://${tenant?.eduDomains?.admin}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {tenant?.eduDomains?.admin}
                            </a>
                          )}
                          {tenant?.eduDomains?.student && (
                            <a
                              href={`http://${tenant?.eduDomains?.student}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {tenant?.eduDomains?.student}
                            </a>
                          )}
                        </>
                        {tenant?.domains ? (
                          <>
                            {tenant?.domains?.admin?.map((admin: string) => (
                              <a
                                href={`http://${admin}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {admin}
                              </a>
                            ))}
                            {tenant?.domains?.student?.map(
                              (student: string) => (
                                <a
                                  href={`http://${student}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {student}
                                </a>
                              )
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                    <td>
                      <p>{tenant?.institutionType || ""}</p>
                    </td>
                    <td>
                      <div className="text-end d-flex justify-content-end align-items-center">
                        <p>
                          {tenant.active}
                          <span>
                            {`Created on ${moment(tenant.createdAt).format(
                              "Do MMMM YYYY"
                            )}`}
                          </span>
                        </p>
                        <div className="more-actions">
                          <img src={DOTS} alt="" />

                          <div className="more-actions_menu">
                            <ul>
                              <li
                                onClick={() =>
                                  navigate(`/edit-institution/${tenant?._id}`)
                                }
                              >
                                Edit
                              </li>
                              <li>Delete</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default Listing;

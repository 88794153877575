import React from "react";
import Sidebar from "../Sidebar";
import Logo from "../../img/logo.png";

const ComponentWrapper = ({ children }: any) => {
  return (
    <>
      <header className="header">
        <img width={132} src={Logo} alt="" />

        <div className="d-flex align-items-center">
          <p>
            Welcome<span>Super Admin</span>
          </p>

          <img
            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            alt=""
          />
        </div>
      </header>

      <section className="main-content">
        <Sidebar />
        <div className="content">
          <div className="p-5">{children}</div>
        </div>
      </section>
    </>
  );
};

export default ComponentWrapper;

import axios from "axios";
import config from "..";

const instance = axios.create();

instance.interceptors.request.use(
  (successfulReq) => successfulReq,
  (error) => Promise.reject(error)
);

instance.defaults.responseType = "json";
instance.defaults.headers.authorization = `Bearer ${window.localStorage.getItem(
  "token"
)}`;
instance.defaults.baseURL = `${config.api}${config.apiVersion}`;

instance.interceptors.response.use(
  (successRes) => successRes,
  ({ response }) => {
    console.log("error : response -> ", response);
    if (response?.status === 401) {
      localStorage.removeItem("token");

      if (!response?.url || !response?.url.includes("auth/local")) {
        window.location.reload();
      }
    }
    return Promise.reject(response);
  }
);
export const client = instance;

import { UploadingContainer } from './file-upload.styled';
import { MdAttachFile } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Button } from 'react-bootstrap';
import * as React from 'react';

export const DocumentPreview = ({ file, onDelete, props }: any) => {
  return (
    <UploadingContainer>
      <MdAttachFile className={'mr-12pt'} />
      <a className="card-title w-auto" href={props}>
        {file && file.split('.').pop()}
      </a>
      <Button onClick={() => onDelete(file)}>
        <AiFillCloseCircle />
      </Button>
    </UploadingContainer>
  );
};

import production from "./production";
import predev from "./predev";
import development from "./development";
import local from "./local";

import merge from "lodash.merge";

const getEnvConfig = () => {
  let config;
  if (process.env.REACT_APP_ENV === "dev") {
    config = development;
  } else if (process.env.REACT_APP_ENV === "predev") {
    config = predev;
  } else if (process.env.REACT_APP_ENV === "production") {
    config = production;
  } else {
    config = local;
  }

  return config;
};

const shared = {};
const config = merge({}, shared, getEnvConfig());

export default config;

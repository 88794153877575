import { MdClose, MdError } from 'react-icons/md';
import { IFileUploadError } from './file-uploader.types';
import { UploadingContainer } from './file-upload.styled';
import { truncateFileName } from './utils';
import React from 'react';

export const FileError = ({
  error,
  onClose,
}: {
  error: IFileUploadError;
  onClose: (error: IFileUploadError) => void;
}) => {
  return (
    <UploadingContainer>
      <MdError className={'text-danger mr-12pt'} />
      <div className={'flex mr-12pt'}>
        <div className="card-title">{truncateFileName(error.file.name, 8)}</div>
        <p className="flex text-black-50 lh-1 mb-0">
          <small>{error.type}</small>
        </p>
      </div>
      <MdClose onClick={() => onClose(error)} />
    </UploadingContainer>

    // <p className="d-flex align-items-center m-0">
    //   {truncateFileName(error.file.name, 20)}
    //   {/* <small className="ml-2">-{error.type}</small> */}
    //   <MdClose className="ml-2 d-none" onClick={() => onClose(error)} />
    // </p>
  );
};

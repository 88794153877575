import React from "react";
import { CheckboxProps } from "./checkbox.types";

export const CheckBox = ({
  name,
  value,
  onChange,
  selectedPermissions,
}: CheckboxProps) => {
  return (
    <>
      <div className="custom-control custom-checkbox ml-5">
        <input
          type="checkbox"
          className="custom-control-input"
          name={`${name}`}
          id={`${name}`}
          onChange={(event) => onChange(value, event)}
          checked={selectedPermissions?.includes(value)}
        />
      </div>
    </>
  );
};

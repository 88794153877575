import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
//@ts-ignore
import { routes } from "./routes";
import PrivateRoute from "./pages/private-route/private-route";
import Login from "./pages/Auth";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((props: any, index: any) => {
          const { route, routeProps, component: Component } = props;
          return (
            <Route
              key={index}
              path={route}
              {...routeProps}
              element={
                <PrivateRoute>
                  <Component />
                </PrivateRoute>
              }
            />
          );
        })}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate replace to="/listing" />} />
        <Route path="*" element={<p>Page Not Found!</p>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { client } from "./client";

export const getTenantList = async () => {
  const { data }: any = await client.get("/tenant");
  return data;
};

export const getTenantById = async (tenantId: any) => {
  const { data }: any = await client.get(`/tenant/${tenantId}`, {
    params: {
      populate: "logo",
    },
  });
  return data;
};

export const createNewTenant = async (payload: any) => {
  const { data }: any = await client.post("tenant", payload);
  return data;
};

export const updateTenant = async (tenantId: any, payload: any) => {
  const { data }: any = await client.patch(`tenant/${tenantId}`, payload);
  return data;
};

import axios from "axios";
import { client } from "./client";

export const uploadFile = async (asset: any) => {
  const formData = new FormData();
  formData.append("file", asset.file);
  if (asset.name) {
    formData.append("name", asset.name);
  }
  const CancelToken = axios.CancelToken;
  const { data }: any = await client.post("/asset/upload", formData, {
    cancelToken: new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      //   cancelRequest = c;
      console.log(c);
    }),
  });
  return data;
};

import { client } from "./client";

export const adminLogin = async (values: any) => {
  const { data }: any = await client.post("/auth/login", values);
  return data;
};

export const currentUserDetails = async () => {
  client.defaults.headers.authorization = `Bearer ${window.localStorage.getItem(
    "token"
  )}`;
  const { data }: any = await client.get("/user/me");
  return data;
};
